import * as React from 'react';
import {Content, ContentProps} from '../components/content';

class Book extends React.Component<ContentProps> {
  render() {
    const {id, containerView} = this.props;
    return (<Content id={id} containerView={containerView}>
      <h1>IoT Development for ESP32 and ESP8266 with JavaScript</h1>
      <a href="https://www.moddable.com/book" aria-label="View the book website"><img src="/projects/book-cover.jpg"alt="Book cover"  width="200px"/></a>
      <p>This book provides detailed information about IoT product development and using the Moddable SDK. It walks through dozens of examples you can run yourself, all available in a <a href="https://github.com/Moddable-OpenSource/iot-product-dev-book" aria-label="View the book's GitHub repository">GitHub repository</a>.</p>
      <p>I co-authored this book with Peter Hoddie. Our <a href="https://www.moddable.com/book" aria-label="View the book website">book website</a> contains more information and allows you to purchase a copy.</p>
    </Content>);}
}
 
export default Book;