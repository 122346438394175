import * as React from 'react';

interface SidebarProps {
  children?: React.ReactNode | React.ReactNode[];
}
 
class Sidebar extends React.Component<SidebarProps> {
  render() {
  const {children} = this.props;
  return (<div className="sidebar">
    {children}
    {/* <a href="/">About</a>
    <a href="/projects">Projects</a>
    <a href="/other">Other</a> */}
  </div>);}
}
 
export default Sidebar;