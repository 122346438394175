import * as React from 'react';

interface TitleProps {
  text: string;
  textAnimateDirection?: "up" | "down" | "left" | "right";
}

class Title extends React.Component<TitleProps> {
  render() { 
    const {text, textAnimateDirection} = this.props;
    let titleClass = ""
    if (textAnimateDirection) titleClass = `animate-${textAnimateDirection}`;
    return ( <div className={"title " + titleClass}>{text}</div> );
  }
}

export default Title;