import * as React from 'react';
import Title from "./title";

interface HeaderProps {
  title: string;
  children?: React.ReactNode | React.ReactNode[];
}

class Header extends React.Component<HeaderProps> {
  render() { 
  const {title, children} = this.props;
  return (<div className="header">
    <Title 
      text={title}
    />
    {children}
  </div>);}
}
 
export default Header;