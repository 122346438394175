import * as React from 'react';
import {Content, ContentProps} from '../components/content';

class Hobbies extends React.Component<ContentProps> {
  render() {
    const {id, containerView} = this.props;
    return (<Content id={id} containerView={containerView}>
      <h1>Hobbies</h1>
      <p>I have a lot of interests outside of work. Right now, these are my favorites.</p>
      <h2>Indoor soccer</h2>
      <p>I manage and play on a women's indoor soccer team called the Buds and Spuds. Our win record is abysmal, but we get an A++ for enthusiasm.</p>
      <hr />
      <h2>Marimba</h2>
      <p>I'm learning to play the marimba at <a href="https://kutandara.org/">Kutandara Studio</a>. I haven't played music in a group setting since middle school band—it's a ton of fun.</p>
      <hr />
      <h2>Night Lights</h2>
      <p><a href="https://nightlightskids.org/" aria-label="View the Night Lights website">Night Lights</a> is an organization that hosts monthly respite nights for parents of special needs children and their siblings. I volunteer to be paired with a child for a night of crafts, games, and entertainment.</p>
      <hr />
      <h2>Curling</h2>
      <p>Yes, curling! That sport with the brooms and the rocks and the ice! I play once a week in a league at <a href="https://rockcreekcurling.com/" aria-label="View the Rock Creek Curling website">Rock Creek Curling</a>.</p>
      <hr />
    </Content>);}
}
 
export default Hobbies;