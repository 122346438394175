import * as React from 'react';

export interface ContentProps {
  id: string;
  containerView: string;
  children?: React.ReactNode | React.ReactNode[];
}

export class Content extends React.Component<ContentProps> {
  render() {
    const {id, containerView, children} = this.props;
    if (id === containerView) {
      return (  
        <div id={id} className="content">
          {children}
        </div>
      )
    } else {
      return null;
    }
  }
}