import * as React from 'react';
import {Content, ContentProps} from '../components/content';

class Experience extends React.Component<ContentProps> {
  render() {
    const {id, containerView} = this.props;
    return (<Content id={id} containerView={containerView}>
    <h1>Experience</h1>

    <div className="experience">
    <img src="logos/lilly.png" alt="Moddable logo" width="70px" />
    <h2>Loxo@Lilly</h2>
    <h5>2023-Present</h5>
    <p>I work as a developer on the Scientific Systems team. My job is to build tools and custom applications for scientists who are creating medicines for cancer patients.</p>
    </div>

    <hr />

    <div className="experience">
    <img src="logos/moddable.png" alt="Moddable logo" width="70px" />
    <h2>Moddable</h2>
    <h5>2017-2022</h5>
    <p>I co-founded Moddable along with a handful of former coworkers from Kinoma.</p>
    <p>In many ways, Moddable is a continuation of the work started at Kinoma to bring JavaScript to IoT devices. However, Moddable focuses on low-cost microcontrollers, modern touch-screen-based user interfaces, and helping companies build professional products.</p>
    <p>My primary job duties were:</p>
    <ul>
      <li>Helping set and execute our company goals and vision</li>
      <li>Developing JavaScript applications for embedded systems with the Moddable SDK</li>
      <li>Attending and hosting meetups and trade shows to network and demonstrate our products</li>
    </ul>
    <p>Our team did (and continues to do) a *ton* of fantastic work. You can read more on the <a className="decorated-link" href="https://www.moddable.com/" aria-label="View the Moddable website">Moddable website</a>.</p>
    </div>

    <hr />

    <div className="experience">
    <img src="logos/marvell.png" alt="Marvell logo" width="70px" />
    <h2>Marvell Semiconductor</h2>
    <h5>2015-2017</h5>
    <p>I worked as a developer relations engineer for the Kinoma team, part of the software division at Marvell.</p>
    <p>Kinoma software brought the power of JavaScript to IoT. The XS engine and KinomaJS JavaScript framework allowed you to build apps for embedded devices and mobile companion apps. Kinoma also made Kinoma Create and Kinoma Element, prototyping devices for makers, hobbyists, and professional product developers.</p>
    <p>Much of my job was developing sample products, applications, and tutorials using KinomaJS and our prototyping hardware. I also provided technical assistance to customers ranging from professional product developers to hobbyist engineers.</p>
    </div>

    <hr />

    <div className="experience">
    <img src="logos/ucb.png" alt="UC Berkeley seal" width="70px" />
    <h2>UC Berkeley</h2>
    <h5>2011-2015</h5>
    <p>I came to Berkeley as an undeclared humanities major but switched gears after taking an intro to CS course in the second semester of my freshman year.</p>
    <p>I graduated with a B.A. in Computer Science.</p>
    </div>

    <hr />
  </Content>);}
}
 
export default Experience;